import { BrowserRouter, Routes, Route } from 'react-router-dom';
import './Components/Sass/main.scss'
import Home from './Components/Pages/Home';
import PrivacyPolicy from './Components/Pages/PrivacyPolicy'
import Oncarbi40 from './Components/Pages/Oncarbi40';
import OncarbiLS from './Components/Pages/OncarbiLS';
import OncarbiDP from './Components/Pages/OncarbiDP';



function App() {
  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/products/oncarbi-40" element={<Oncarbi40 />} />
          <Route path="/products/oncarbi-ls" element={<OncarbiLS />} />
          <Route path="/products/oncarbi-dp" element={<OncarbiDP />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
